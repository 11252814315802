header {
  .admin-bar {
    @apply container flex flex-wrap items-center justify-start gap-y-2 gap-x-4;

    &__container {
      @apply bg-tertiary py-2;
    }

    &__title {
      @apply hidden md:block text-sm font-bold text-black;
    }

    &__button {
      @apply button button__xs bg-white/[0.15] text-black font-normal;
    }

    // fake justify-content: space-between as of 3rd item
    &__title + .admin-bar__button + .admin-bar__button {
      @apply ml-0 md:ml-auto;
    }
  }

  #flash-messages-container {
    @apply mt-28 md:mt-0;
  }

  &.with-admin-bar {
    #flash-messages-container {
      @apply mt-40 md:mt-0;
    }

    #menu-bar-container {
      @apply mt-[140px] md:mt-0;
    }
  }

  #sticky-header-container {
    @apply fixed w-full top-0 shadow-lg z-40 bg-white md:relative transition-top duration-300;
  }

  .main-bar {
    @apply container grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-4 items-center py-5 h-24;

    &__container {
      @apply relative w-full;
    }

    &__logo {
      @apply col-span-3 xl:col-span-2 prose prose-img:max-h-16 prose-img:my-0;
    }

    &__logo-desktop {
      @apply hidden lg:block;
    }

    &__logo-mobile {
      @apply lg:hidden;
    }

    &__search {
      @apply hidden md:block col-span-2 col-start-5 xl:col-start-4;

      form {
        @apply block relative rounded text-md bg-background;
      }

      input[type="text"] {
        @apply block bg-transparent w-full px-4 py-1.5;
      }

      button[type="submit"] {
        @apply absolute ltr:right-2 rtl:left-2 inset-y-2 text-secondary;
      }
    }

    &__menu-mobile {
      @apply lg:hidden flex flex-row-reverse items-center;
    }

    &__links-desktop,
    > *:last-child {
      @apply col-span-1 md:col-start-8 lg:col-start-8 lg:col-span-5 justify-self-end;
    }

    &__links-desktop {
      @apply hidden lg:flex items-center justify-between text-center divide-x-2 divide-gray-3 ml-auto [&>*]:px-4 xl:[&>*]:px-6 first:[&>*]:pl-0 last:[&>*]:pr-0;

      &__item {
        @apply flex flex-col items-center text-secondary px-2 py-1 rounded hover:underline hover:bg-background;

        &-wrapper {
          @apply flex gap-x-4 xl:gap-x-6;
        }

        &-account {
          @apply w-6 h-6 flex items-center justify-center bg-secondary text-white rounded-full text-xs font-bold mr-2;
        }
      }

      /* overwrite default dropdown styles */
      [data-target*="dropdown"] > span:only-of-type {
        @apply gap-0;
      }

      svg {
        @apply w-5 h-5 fill-current;
      }

      svg + span {
        @apply text-sm first-letter:uppercase;
      }
    }

    &__links-mobile {
      @apply fixed bottom-0 left-0 z-40 bg-white w-full px-4 py-3 flex justify-between text-secondary shadow-[0_-4px_6px_rgba(198,198,198,0.25)];

      &__trigger {
        @apply flex flex-col items-center text-secondary cursor-pointer md:pl-4 md:border-l border-gray-3;

        svg {
          @apply w-6 h-6 fill-current;
        }

        span {
          @apply text-sm first-letter:uppercase;
        }
      }

      &__dropdown {
        @apply absolute top-full left-0 z-30 bg-white;
      }

      &__dropdown-before {
        @apply before:block before:absolute before:-top-10 before:right-12 before:content-[""] before:bg-white before:w-24 before:h-12 before:-mt-8;
      }

      &__account {
        @apply fixed top-0 left-0 w-full h-full z-30 bg-white;

        ul {
          @apply my-6 divide-y divide-gray-3;
        }

        li {
          @apply py-4;
        }

        .dropdown__item {
          @apply text-md;
        }

        .main-bar {
          @apply border-b border-gray;
        }

        .main-bar__avatar {
          @apply w-12 h-12;
        }

        .main-bar__avatar-name {
          @apply w-12 h-12 text-2xl;
        }

        .account-container {
          @apply before:block before:absolute before:top-16 before:right-14 before:content-[""] before:bg-white before:w-2 before:h-12 before:-mt-8;
        }
      }

      &__item {
        @apply relative;
      }

      &__search {
        @apply fixed top-0 left-0 w-full h-full z-30 bg-white;
      }

      &__login {
        @apply w-auto h-full flex flex-row items-center relative text-secondary gap-1 pl-2 pr-2;

        svg {
          @apply w-8 h-6 fill-current;
        }

        svg + span {
          @apply text-sm first-letter:uppercase w-auto;

          flex-shrink: 0;
        }
      }
    }

    &__dropdown {
      @apply divide-y divide-gray-3 z-20 w-64;

      &-container {
        @apply relative cursor-pointer;
      }

      &-notification {
        @apply w-2.5 h-2.5 rounded-full bg-primary;
      }

      &-trigger {
        @apply flex flex-col items-center text-secondary relative px-2 py-1;
      }
    }

    &__notification {
      @apply w-2.5 h-2.5 rounded-full block absolute -top-0.5 right-1.5 bg-primary;
    }

    &__avatar {
      @apply block w-6 h-6 rounded-full border border-secondary overflow-hidden mr-2;

      span {
        @apply w-full h-full rounded-full overflow-hidden;
      }

      img {
        @apply object-cover w-full h-full;
      }
    }

    &__avatar-name {
      @apply w-6 h-6 flex items-center justify-center bg-secondary text-white rounded-full text-xs font-bold mr-2;
    }

    /* overwrite default dropdown styles */
    [id*="dropdown-menu"] {
      @apply py-0 mx-0 w-full;

      &[aria-hidden="true"] {
        @apply md:hidden;
      }
    }
  }

  .menu-bar {
    @apply container h-full flex justify-between items-center lg:relative last-of-type:[&>svg]:hidden;

    &__container {
      @apply bg-primary relative h-14;
    }

    &__breadcrumb-desktop {
      @apply hidden lg:flex justify-between items-center gap-2.5 [&>*]:text-lg [&>*]:text-white;

      &__dropdown-trigger {
        @apply flex rounded px-2 py-1 z-20;

        &:hover {
          @apply z-10 relative before:content-[''] before:absolute before:w-[calc(100%+3rem+1px)] before:min-w-[10rem] before:h-40 before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/4 before:-z-10;
        }

        svg {
          @apply w-6 h-6 fill-current;
        }
      }

      &__dropdown-wrapper {
        @apply flex items-center cursor-pointer rounded hover:backdrop-brightness-75 focus:backdrop-brightness-75 focus:outline-none;
      }

      &__dropdown-content {
        @apply absolute z-20 top-full cursor-auto;

        &-secondary {
          @apply absolute z-20 top-[calc(100%+12px)];
        }
      }

      /* overwrite default dropdown styles */
      [id*="dropdown-menu"] {
        @apply cursor-auto;

        &[aria-hidden="true"].no-animation {
          @apply opacity-0 invisible;
        }

        &[aria-hidden="true"]:not(.no-animation) {
          animation-name: fadeOut;
          animation-duration: 300ms;
          animation-delay: 300ms;
          animation-fill-mode: forwards;

          &:hover {
            animation: none;
          }
        }

        &[aria-hidden="false"] {
          animation-name: fadeIn;
          animation-duration: 300ms;
          animation-fill-mode: forwards;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            visibility: hidden;
          }

          to {
            opacity: 1;
            visibility: visible;
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
            visibility: visible;
          }

          to {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      [data-target*="dropdown"] {
        @apply w-auto p-0 flex items-center gap-0 last-of-type:[&>svg]:block;

        > svg {
          @apply w-5 h-5 text-white;
        }
      }
    }

    &__breadcrumb-mobile {
      @apply block lg:hidden w-full z-20;

      &__dropdown-trigger {
        @apply flex items-center justify-between text-white;

        span {
          @apply flex flex-wrap md:flex-nowrap gap-x-2.5 overflow-hidden text-white;
        }

        svg {
          @apply w-6 h-6 fill-current;
        }
      }

      /* overwrite default dropdown styles */
      [id*="dropdown-menu"] {
        @apply mx-0 py-0 border-0;

        &[aria-hidden="true"] {
          @apply md:hidden lg:flex;
        }
      }

      [data-target*="dropdown"] {
        @apply md:block lg:hidden w-auto last-of-type:[&>svg]:block ml-auto;

        > svg {
          @apply text-white;
        }
      }
    }

    &__actions {
      @apply hidden lg:flex justify-between items-center gap-6;

      &-mobile {
        @apply [&_svg]:w-4 [&_svg]:h-4 [&_span]:sr-only;
      }
    }

    &__language-chooser {
      @apply absolute top-full left-0 bg-white rounded w-full bg-gray-5;
    }

    &__main-dropdown {
      @apply bg-white divide-y divide-gray-3 rounded-b shadow-lg text-black w-full lg:w-[1280px] h-screen md:h-auto;

      &__bottom,
      &__top {
        @apply flex flex-col md:flex-row justify-between p-4 md:p-8 gap-x-8;

        &-right {
          @apply hidden md:block md:w-1/2;
        }
      }

      &__bottom {
        @apply hidden md:flex;
      }

      &__title {
        @apply hidden h4 md:flex md:h3;
      }

      &__subtitle {
        @apply hidden text-md md:flex md:text-lg text-gray-2 mt-5;
      }

      &__menu {
        @apply w-full md:w-1/2 mt-0 grid md:grid-cols-2 gap-x-6 text-secondary;

        > * {
          @apply py-3 md:py-3.5 border-b last:border-0 border-gray-3;

          /* since the grid has 2 columns, remove the border for these last 2 columns */
          &:nth-last-child(-n + 2) {
            @apply md:border-0;
          }
        }

        a {
          @apply flex items-center justify-start gap-1 font-semibold text-lg text-secondary;

          span {
            @apply min-w-0 truncate;
          }

          svg {
            @apply flex-none fill-current;
          }
        }
      }
    }

    &__secondary-dropdown {
      @apply absolute top-full left-0 lg:relative flex justify-between bg-white rounded-b shadow-lg text-black w-full lg:w-[1000px];

      &__left {
        @apply hidden md:block w-3/4 p-8 text-md;
      }

      &__title {
        @apply h4 md:h3;
      }

      &__menu {
        @apply w-full md:w-1/4 bg-primary px-4 md:px-8 pt-0 pb-3 md:py-3 divide-y divide-gray-3 text-white;

        > * {
          @apply py-3 md:py-3.5;
        }

        a {
          @apply flex items-center justify-start gap-1 font-semibold text-lg text-white;

          span {
            @apply min-w-0 truncate;
          }

          svg {
            @apply flex-none fill-current;
          }
        }
      }

      &__metadata {
        @apply flex items-center text-sm space-x-6 py-8;

        > span {
          @apply flex items-center space-x-2;
        }
      }
    }
  }
}
